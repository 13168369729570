.users-managing-panel {
    padding: 1em;
}

.users-managing-wrapper {
    display: flex;
    gap: 1em;
    flex-direction: column;
    overflow-y: hidden;
}

.users-manage-list-modal {
    top: 10px;
}

.users-manage-list-table-column-name {
    color: rgb(244, 113, 116);
}

.users-manage-list-title {
    text-align: center;
}

.users-manage-list-table-expandable-item {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.users-manage-list-table-expandable-item-title {
    font-style: italic;
}

.users-manage-list-table-column-key {
    display: flex;
    gap: 10px;
}

.users-manage-list-table-column-key > Button {
    flex: 1;
}

.users-manage-list-table-column-current-role {
    display: flex;
    gap: 10px;
}

.users-manage-list-table-header-search-field {
    width: 100%;
}

.users-manage-list-table-column-current-role-select {
    width: 100%;
    min-width: 120px;
}
