.layout-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    color: #112D4E;
    background-color: #F9F7F7;
}

.layout-header {
    width: 100%;
    height: 10vh;
    padding: 1em 1em 0 1em;
    box-sizing: border-box;
}

.layout-header > div {
    background-color: #DBE2EF;
    border: .2em dashed #3F72AF;
    border-radius: .5em;
    height: 100%;
}

.layout-content-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1em;
    gap: 1em;
    flex: 1;
    box-sizing: border-box;
}

.left-content-wrapper {
    flex: 0.3;
    flex-wrap: wrap;
    background-color: #DBE2EF;
    border: .2em dashed #3F72AF;
    border-radius: .5em;
}

.left-content-wrapper > div {
    padding: 1em 1em 0 1em;
}

.right-content-wrapper {
    flex-wrap: wrap;
    flex: 0.7;
    background-color: #DBE2EF;
    border: .2em dashed #3F72AF;
    border-radius: .5em;
    padding: 1em 1em 0 1em;
}

/* Desktop */
@media (min-width: 1024px) {

}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {

}

/* Smartphone */
@media (max-width: 767px) {

}
