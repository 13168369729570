.searchInput {
    width: 100%;
    margin-bottom: 1em;
}

.contentWrapper {
    display: flex;
    height: 82vh;
    flex-direction: column;
    position: relative;
}

.listWrapper {
    overflow: auto;
}

.scrollListWrapper {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 1rem;
}

.noProjectsFound {
    position: absolute;
    left: 0;
    right: 0;
    margin-inline: auto;
    width: fit-content;
}

.textScroll {
    text-align: center;
}
