.sections-wrapper {
    display: flex;
    height: 100vh;
    background-color: #F9F7F7;
    color: #112D4E;
}

.carousel-wrapper, .auth-wrapper {
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-wrapper {
    padding: 1em;
    position: relative;
}

.carousel-logo-overlap {
    position: absolute;
    top: 3%;
    left: 3%;
    z-index: 1000;
}

.carousel-logo-overlap img {
    max-width: 150px;
    filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(296deg) brightness(106%) contrast(91%);
    user-select: none;
}

.auth-header {
    margin-top: 0;
    margin-bottom: 0;
    user-select: none;
}

.auth-link {
    text-decoration: none;
    color: #3F72AF;
    transition: color .2s ease;
    user-select: none;
}

.auth-link:hover {
    text-decoration: none;
    color: #112D4E;
}

.carousel {
    width: 50vw;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    border-radius: 1em;
    overflow: hidden;
}

.carousel img {
    width: 50vw;
    height: 100vh;
    object-fit: cover;
    margin: auto;
    border-radius: 1em;
    user-select: none;
}

.no-profile-text {
    user-select: none;
}

.ant-carousel,
.slick-slider,
.slick-list,
.slick-track {
    height: 100% !important;
}

.slick-slide > div:first-child {
    height: 100% !important;
}

.auth-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}

.auth-copyright {
    position: absolute;
    bottom: 1.5%;
}
