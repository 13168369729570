.header-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.header-college-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-left: 1em;
    gap: .5em;
}

.header-college-title-link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5em;
}

.header-college-title-link-wrapper {
    transition: filter 0.3s ease-in-out;

    &:hover {
        filter: brightness(250%);
    }
}

.header-college-title-admin {
    transition: filter 0.3s ease-in-out;

    &:hover {
        filter: brightness(250%);
    }
}

.header-college-title-link, .header-college-title-admin {
    text-decoration: none;
    color: #112D4E;
    cursor: pointer;
}

.header-college-title-link > img {
    width: 48px;
    filter: invert(11%) sepia(75%) saturate(1250%) hue-rotate(187deg) brightness(93%) contrast(91%);
}

.header-user-info-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1em;
    gap: .5em;
}

.header-avatar-image {
    padding: .2rem;
    width: 2.5rem;
    border-radius: 50%;
    border: 2px solid #112D4E;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
    -webkit-user-drag: none;
}

.header-avatar-image:hover {
    border-color: #8596b5;
}

.header-user-info {
    display: flex;
    flex-direction: column;
}

.header-user-info-title-name, .header-user-info-title-role {
    margin-bottom: 0 !important;
    text-align: right;
    width: 100%;
    user-select: none;
}

.header-logout-btn {
    font-size: 2.5rem;
    padding: .2rem;
    border: 2px solid #112D4E;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
    -webkit-user-drag: none;
}

.header-logout-btn:hover {
    border-color: #8596b5;
}
