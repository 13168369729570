.admin-list-wrapper {
    padding: 1em 1em 0 1em;
    display: flex;
    flex-direction: column;
}

.admin-list-title-func, .admin-list-title-security {
    margin-top: 0;
}

.admin-list-wrapper > div {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.admin-list-btn {
    width: 100%;
}

.admin-list-security-wrapper > div {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
