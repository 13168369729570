.form-item-login {
    margin-top: 1em;
    margin-bottom: 1em;
}

.form-input-login {
    padding: .5rem;
    min-width: 250px;
}

.college-header {
    color: #3F72AF;
    user-select: none;
    margin-top: 0;
    margin-bottom: 1em;
    word-break: break-all;
    min-width: 250px;
}

.form-wrapper-remember-me {
    display: flex;
    justify-content: space-between;
}

.remember-me-text {
    color: #112D4E;
    user-select: none;
}

.forget-password-text {
    user-select: none;
}

.form-btn-submit-login, .form-btn-google-login {
    width: 100%;
    padding: 1.5em;
    min-width: 250px;
}

.form-separator-login {
    margin-bottom: 1em;
    margin-top: 1em;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    user-select: none;
}

.form-separator-login::before, .form-separator-login::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #112D4E;
    margin: 0 8px;
}