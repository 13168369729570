.sectionContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    overflow-y: scroll;
    padding-right: 0;
}

.contentCopyLink {
    font-size: 24px;
    opacity: 1;
    transition: .2s ease;
}

.contentCopyLink:hover {
    opacity: 0.5;
}

.contentCopyLinkWrapper {
    display: flex;
    gap: 5px;
}

.emptyLogoContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 100%;
    color: gray;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.contentWrapperProjectName {
    display: flex;
    gap: 5px;
}

.contentWrapperProjectNameText {
    font-size: 18px;
}

.contentWrapperProjectKeywordsText {
    color: gray;
    font-size: 12px;
    font-style: italic;
}

.contentWrapperAuthorLinksBlock {
    display: flex;
    justify-content: space-between;
}

.contentWrapperAuthorBlock {
    display: flex;
    gap: 5px;
    align-items: center;
}

.contentWrapperAuthorText {
    font-style: italic;
    color: gray;
}

.contentWrapperCoverImage {
    max-width: 595px !important;
    max-height: 842px !important;
    padding: 0;
    margin: 0;
    border: 1px solid lightgray;
}

.contentWrapperLinksBlock {
    display: flex;
    gap: 10px;
    align-items: center;
}

.textSpecialityContent {
    text-align: center;
}

.contentDetailsDescriptionMobile {
    margin-top: 5%;
    text-align: justify;
    height: 100%;
}

.contentWrapperDownBlock {
    display: flex;
    width: 100%;
    margin-top: .5%;
    height: 100%;
}

.contentWrapperImageAdminButtons {
    display: flex;
    flex-direction: column;
    flex: 0.3;
    height: 100%;
}

.contentDetailsDescription {
    flex: 0.7;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
}

.contentDetailsDescription > div {
    height: 100%;
}

.contentWrapperFullWidth {
    width: 100%;
}

.contentWrapperFullSpace {
    flex: 1;
}

.contentWrapperRedIcon {
    color: red;
}

.contentWrapperCoverImageBlockMobile {
    display: flex;
    justify-content: center;
}

.contentWrapperAdminButtonsBlock {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 1%;
    gap: 1%;
}

.contentWrapperDatesBlock {
    display: flex;
    justify-content: space-around;
    margin: 20px;
}
