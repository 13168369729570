.project-approvement-title {
    margin-top: 0;
    user-select: none;
    text-align: center;
}

.approval-project-wrapper {
    padding: 1em;
}

.project-approvement-modal {
    top: 20px;
}

.project-approvement-table {
    margin-top: 1em;
}

.project-approvement-table-title-input {
    width: 100%;
}

.project-approvement-table-file {
    width: 100%;
}

.project-approvement-table-media {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.project-approvement-table-approve {
    display: flex;
    gap: 5px;
    justify-content: center;
}

.project-approvement-table-expandable {
    display: flex;
    gap: 10px;

    width: 100%;
}

.project-approvement-table-expandable-description {
    overflow-y: scroll;
}

.project-approvement-table-expandable-content {
    flex: 1;
}

.project-approvement-table-expandable-full-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.project-approvement-table-expandable-key-words {
    color: gray;
    font-size: 12px;
    font-style: italic;
    margin: 0;
}

.project-approvement-table-expandable-full-name > div {
    margin: 0;
}
