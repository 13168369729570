.add-research-project-wrapper {
    padding: 1em;
}

.form-title-create-research-project {
    text-align: center;
    margin-top: 0;
    user-select: none;
}

.load-cover-image {
    color: gray;
    border: 1px dashed lightgray;
    border-radius: 10px;
    cursor: pointer;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.form-create-research-project-image {
    max-width: 250px;
    max-height: 300px;
    cursor: pointer;
}

.form-create-research-project-file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}

.form-create-research-project-file-icon {
    align-self: center;
    font-size: 40px;
    color: rgb(80, 205, 95);
}

.form-create-research-project-file-text {
    color: gray;
}

.form-create-research-project-load {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;

    cursor: pointer;
    padding: 10px;
    border: 1px dashed gray;
    border-radius: 6px;

    transition: 0.3s ease;
}

.form-create-research-project-load:hover {
    padding: 10px;
    border: 1px dashed #1677ff;
    background-color: rgba(22, 119, 255, 0.1);
    border-radius: 6px;
}

.form-create-research-project-load-text {
    color: gray;
    text-align: center;
    width: 100%;
}

.form-create-research-project-load-text:hover {
    color: #1677ff;
}

.form-create-research-project-item {
    margin-bottom: 10px;
}

.form-create-research-project-btn {
    width: 100%;
}

.form-create-research-project-react-quill > .ql-container {
    padding: 12px 15px;
}
