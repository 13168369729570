.get-image-wrapper {
    display: flex;
    justify-content: center;
}

.get-image-image {
    max-width: 195px;
    max-height: 342px;
    padding: 0;
    margin: 0
}
