@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

* {
    font-family: Poppins, sans-serif;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

p, span, a {
    font-size: 1em;
}

html, body {
    margin: 0;
    padding: 0;
}

h1, h2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: lightgrey;
}

.grecaptcha-badge {
    display: none;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.hide-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
