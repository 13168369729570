.form-initials-wrapper {
    display: flex;
    gap: .5em;
}

.form-input-register {
    padding: .5rem;
}

.form-btn-submit-register, .form-btn-google-register {
    width: 100%;
    padding: 1.5em;
    min-width: 250px;
}

.form-separator-register {
    margin-bottom: 1em;
    margin-top: 1em;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    user-select: none;
}

.form-separator-register::before, .form-separator-register::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #112D4E;
    margin: 0 8px;
}

