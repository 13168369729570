.categories-managing-panel {
    padding: 1em;
}

.update-category-modal {
    top: 20px;
}

.update-category-title {
    text-align: center;
}

.update-category-space-compact {
    width: 100%;
}

.update-category-add-category-btn {
    font-size: 15px;
    color: gray !important;
    border-color: gray !important;
}

.update-category-add-category-btn:hover {
    color: rgb(22, 119, 255) !important;
    border-color: rgb(22, 119, 255) !important;
}
