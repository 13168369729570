.search-title {
    margin-top: 0;
}

.form-item-filters-wrapper {
    margin-bottom: 1em;
}

.form-section-filters {
    width: 100%;
}

.form-btn-filters {
    width: 100%;
}
