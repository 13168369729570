.landing-wrapper {
    display: flex;
    height: calc(100vh - 20px);
    padding: 10px;
    gap: 10px;
    background-color: #F9F7F7;
}

.left-landing-section {
    flex: 0.4;
    overflow: hidden;
    border-radius: 1em;
    position: relative;
}

.landing-header-text-wrapper {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.landing-header-text-wrapper > h1 {
    color: #F9F7F7;
    text-align: center;
    user-select: none;
}

.left-landing-section > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s ease;
}

.left-landing-section > img:hover {
    transform: scale(1.03);
}

.right-landing-section {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
}

.inner-top-landing-section {
    display: flex;
    width: 100%;
    flex: 0.6;
    gap: 10px;
}

.inner-top-landing-section > div {
    flex: 0.5;
}

.inner-top-left-landing-section {
    border: 0.2em dashed #3F72AF;
    border-radius: 1em;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-top-right-landing-section {
    border-radius: 1em;
    overflow: hidden;
}

.inner-top-right-landing-section > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1em;
    transition: .5s ease;
}

.inner-top-right-landing-section > img:hover {
    transform: scale(1.03);
}

.inner-bottom-landing-section {
    display: flex;
    width: 100%;
    flex: 0.4;
    overflow: hidden;
    height: 100%;
    background-color: #112D4E;
    border-radius: 1em;
    position: relative;
}

.statistics-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 3.5em;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.header-tooltip-statistic {
    opacity: 1;
    transition: .2s ease;
}

.header-tooltip-statistic:hover {
    opacity: 0.7;
}

.inner-top-left-landing-section > div {
    display: flex;
    flex-direction: column;
    gap: 3.5em;
}

.inner-top-left-landing-section > div > div {
    display: flex;
    flex-direction: column;
    gap: .5em;
}

.inner-top-left-landing-section > div > img {
    max-width: 200px;
    filter: invert(16%) sepia(9%) saturate(5603%) hue-rotate(177deg) brightness(93%) contrast(97%);
}

.landing-auth-button {
    font-size: 1em;
    width: 100%;
}
