.google-logo {
    width: 20px;
    height: 20px;
}

#credential_picker_container {
    position: absolute !important;
    left: 1.5%;
    top: 0;
}

/* Desktop */
@media (min-width: 1024px) {
    .google-button {
        display: flex;
        gap: 5px;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        height: 32px;
    }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
    .google-button {
        display: flex;
        gap: 5px;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        height: 40px;
    }
}

/* Smartphone */
@media (max-width: 767px) {
    .google-button {
        display: flex;
        gap: 5px;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        height: 40px;
    }
}
