.form-btn-submit-recover-password {
    width: 100%;
    padding: 1.5em;
    min-width: 250px;
}

.form-input-recover-password {
    padding: .5rem;
    min-width: 250px;
}
