.content-card-wrapper {

}

.content-card {
    max-width: 16rem;
    height: 32rem;
    background-color: #F9F7F7;
    border: 1px solid #F9F7F7;
}

.content-card-image {
    border-radius: .5rem;
}

.content-card-title {
    display: -webkit-box; /* Required for line clamping */
    -webkit-line-clamp: 2; /* Set the number of lines to show */
    -webkit-box-orient: vertical; /* Set the orientation of the box to vertical */
    overflow: hidden; /* Hide the overflow */
    text-overflow: ellipsis; /* Add the ellipsis (...) at the end */
    font-weight: bold;
}

.content-card-title::-webkit-scrollbar {
    display: none;
}

.content-card-meta {
    text-decoration: none;
}
