.form-item-recovery-password-confirmation {
    margin-top: 1em;
    margin-bottom: 1em;
}

.form-input-recovery-password-confirmation {
    padding: .5rem;
    min-width: 250px;
}

.form-btn-submit-recover-password-confirmation {
    width: 100%;
    padding: 1.5em;
    min-width: 250px;
}
